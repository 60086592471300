<template>
	<div class="main-content">
		<ktv-breadcrumb title="Menu List" page="Group" folder="System" />
		<ktv-table :columns="columns" :is-loading="tableLoad" :rows="rows" :filter="false">
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span><i class="nav-icon text-14 i-Arrow-Down-in-Circle" /></span>
						</template>
						<b-dropdown-item href="#">
							<a href="#" @click="openForm('listuser', props.row)">
								<i class="i-Eye text-14 text-success mr-2" />
								View
							</a>
						</b-dropdown-item>
						<b-dropdown-item href="#">
							<a href="#" @click="openForm('view', props.row)">
								<i class="i-Eye text-14 text-success mr-2" />
								View
							</a>
						</b-dropdown-item>
						<b-dropdown-item>
							<a href="#" @click="openForm('update', props.row)">
								<i class="i-Pen-3 text-14 text-success mr-2" />
								Update
							</a>
						</b-dropdown-item>
						<b-dropdown-item>
							<a href="#" @click="deleteData(props.row)">
								<i class="i-Close-Window text-14 text-danger mr-2" />
								Delete
							</a>
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>
		<ktv-modal v-model="modalsform" size="lg" :title="titleModals" @close="modalsform = false">
			<template #content>
				<b-form-group class="col-md-6 mb-3" label="SMEID" label-for="input-1">
					<b-form-input v-model="form.smeID" type="text" required readonly="true" placeholder="First Name" />
				</b-form-group>
				<b-form-group class="col-md-6 mb-3" label="Entity ID" label-for="input-1">
					<b-form-input v-model="form.entityID" type="text" required readonly="true" placeholder="Entity ID" />
				</b-form-group>
				<b-form-group class="col-md-6 mb-3" label="Institution Name" label-for="input-1">
					<b-form-input v-model="form.companyName" type="text" required placeholder="Institution Name" />
				</b-form-group>
				<b-form-group class="col-md-6 mb-3" label="Owner Name" label-for="input-1">
					<b-form-input v-model="form.ownerName" type="text" required placeholder="Owner Name" />
				</b-form-group>
				<b-form-group class="col-md-6 mb-3" label="Phone Number" label-for="input-1">
					<b-form-input v-model="form.phoneNumber" type="text" required placeholder="Phone Number" />
				</b-form-group>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="modalsform = false"> Save </b-button>
				<div style="margin-right: 30px" />
				<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="modalsform = false"> Cancel </b-button>
			</template>
		</ktv-modal>
		<ktv-modal v-model="modalsListUser" size="lg" title="List User Group" @close="modalsListUser = false">
			<template #content>
				<ktv-table :columns="columnsListUser" :filter="false" :is-loading="tableLoadListUser" :rows="rowsListUser" />
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvModal, KtvTable } from "@/components"

	export default {
		metaInfo: {
			title: "Menus",
		},
		components: { KtvModal, KtvTable },
		data() {
			return {
				tableLoad: true,
				modalsform: false,
				tableLoadListUser: true,
				modalsListUser: false,
				rowsListUser: [],
				columnsListUser: [
					{
						label: "User ID",
						field: "UserId",
						thClass: "text-left",
					},
					{
						label: "Full Name",
						field: "UserRealName",
						thClass: "text-left",
					},
					{
						label: "User Name",
						field: "UserName",
						thClass: "text-left",
					},
					{
						label: "Active",
						field: "UserActive",
						thClass: "text-left",
					},
				],
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Group Name",
						field: "GroupName",
						thClass: "text-left",
					},
					{
						label: "Description",
						field: "GroupDescription",
						thClass: "text-left",
					},
					{
						label: "Unit",
						field: "UnitName",
						thClass: "text-left",
					},
					{
						label: "Active User",
						field: "ActiveUser",
						thClass: "text-left",
					},
				],
				rows: [],
				titleModals: "",
				form: {
					smeID: "",
					entityID: "",
					companyName: "",
					ownerName: "",
					phoneNumber: "",
				},
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			openForm(opsidisplay, item) {
				if (opsidisplay === "view" || opsidisplay === "update") {
					let payload = { userId: item.UserId }
					this.$http
						.Get(payload, this.$urlApi.group.detail)
						.then((res) => {
							this.form = res.results.data
						})
						.catch((err) => {
							this.$swal("Error!", err.error_massage, "error")
						})
					this.modalsform = true
				}
				if (opsidisplay === "view") {
					this.titleModals = "View Data"
					this.hideBtnSave = true
				}
				if (opsidisplay === "update") {
					this.titleModals = "Edit Data"
					this.hideBtnSave = false
				}
				if (opsidisplay === "listuser") {
					let payload = {
						groupId: item.UserId,
						rowPerpage: 15,
						currentPage: 1,
					}
					this.$http
						.Get(payload, this.$urlApi.group.listUserGroup)
						.then((res) => {
							this.tableLoadListUser = false
							this.rowsListUser = res.results.data
						})
						.catch((err) => {
							this.$swal("Error!", err.error_massage, "error")
						})

					this.modalsListUser = true
				}
			},
			deleteData() {
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted",
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Yes",
				}).then((result) => {
					if (result.value) {
						this.$swal("Deleted!", "Your file has been deleted.", "success")
					}
				})
			},
			getData() {
				let payload = {
					rowPerpage: 15,
					currentPage: 1,
				}
				this.$http
					.Get(payload, this.$urlApi.group.list)
					.then((response) => {
						this.tableLoad = false
						if (response.success) {
							this.rows = response.results.data
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.tableLoad = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		},
	}
</script>
